<template>
<div class="product-mapper-block" v-if="showBlock">
    <v-overlay>
        <v-card class="content" v-if="showLoadingData">
            <div class="loading-box">
                <v-card-title>
                    <v-img class="rotate" src="~@/assets/images/logo.svg" height="50px" contain></v-img>
                </v-card-title>
                <v-card-text class="text-center">
                    Initiating product mapper...
                </v-card-text>
            </div>
        </v-card>
        <v-card class="content" v-else>
            <v-toolbar 
                @click="closeDialog()"
                color="white"
                class="text--primary top-bar"
                flat
            >
                <v-icon
                    class="icon text--primary mr-5"
                >
                    keyboard_arrow_left
                </v-icon>
                Back to product list
            </v-toolbar>
            <div class="upper-row">
                <v-row>
                    <v-col cols="8">
                        <v-card-title>
                            <v-icon
                                color="orange"
                                large
                                class="mr-3"
                            >
                                link
                            </v-icon>
                            <h2>
                                Link to Existing Product
                            </h2>
                            <div>
                                Link this product to a product with the same SKU in your destination store.
                            </div>
                        </v-card-title>
                    </v-col>
                    <v-col cols="4 text-right">
                        <v-btn
                            large
                            color="primary"
                            class="establish-link-btn"
                            @click="mapProducts()"
                        >Establish Link</v-btn>
                    </v-col>
                </v-row>
            </div>
            <div class="search-shop-bar">
                <v-row>
                    <v-col cols="6 left">
                        <v-card 
                            light
                            outlined
                            class="shop-info-wrapper overflow-hidden"
                        >
                            <v-card-text
                                class="info-block"
                            >
                                <div class="from-label float-left">
                                    FROM
                                </div>
                                <div class="float-left shop">
                                    <v-img
                                        width="25"
                                        v-if="currentPartnerShop.platform === 'shopify'"
                                        src="@/assets/images/shopify_logo.png"
                                        class=""
                                    ></v-img>
                                    <v-img
                                        width="30"
                                        v-if="currentPartnerShop.platform === 'woocommerce'"
                                        src="@/assets/images/woo_logo.png"
                                    ></v-img>
                                    <div class="shop-name">
                                        <h3>{{ currentPartnerShop.store_domain }}</h3>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                    </v-col>
                    <v-col cols="6 right">
                        <v-card
                            light
                            outlined
                            class="shop-info-wrapper overflow-hidden"
                        >
                            <v-card-text
                                class="info-block"
                            >
                                <div class="to-label float-left">
                                    TO
                                </div>
                                <div class="float-left shop">
                                    <v-img
                                        width="25"
                                        v-if="currentShop.platform === 'shopify'"
                                        src="@/assets/images/shopify_logo.png"
                                    ></v-img>
                                    <v-img
                                        width="30"
                                        v-if="currentShop.platform === 'woocommerce'"
                                        src="@/assets/images/woo_logo.png"
                                    ></v-img>
                                    <div class="shop-name">
                                        <h3>{{ currentShop.store_domain}}</h3>
                                    </div>
                                </div>
                            </v-card-text>
                        </v-card>
                        
                        <v-autocomplete
                            v-model="selectedProductId"
                            outlined
                            label="Search using Title or Product ID"
                            prepend-inner-icon="search"
                            dense
                            light
                            :items="matchingProducts"
                            item-text="title"
                            item-value="ref_id"
                            class="mt-3"
                            :search-input.sync="search"
                        >
                        </v-autocomplete>
                    </v-col>
                </v-row>
            </div>

            <div class="products-holding-bar">
                <v-row>
                    <v-col cols="6 left">
                        <product-summary-component v-if="currentViewShopProduct != null"
                            :display-product="currentViewShopProduct"
                        ></product-summary-component>
                    </v-col>
                    <v-col cols="6 right">
                        <v-card v-if="searchingMatchingProducts && !loadingDestinationProduct"
                            light
                            outlined
                            class="searching-matching-products-block"
                        >
                            <div class="loading-box no-product-block">
                                <v-card-title>
                                    <v-img class="rotate" src="~@/assets/images/logo.svg" height="50px" contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center">
                                    Searching Matching Proudcts...
                                </v-card-text>
                            </div>
                        </v-card>
                        <v-card v-else-if="!searchingMatchingProducts && loadingDestinationProduct"
                            light
                            outlined
                            class="loading-destination-product-block"
                        >
                            <div class="loading-box no-product-block">
                                <v-card-title>
                                    <v-img class="rotate" src="~@/assets/images/logo.svg" height="50px" contain></v-img>
                                </v-card-title>
                                <v-card-text class="text-center">
                                    Loading Product...
                                </v-card-text>
                            </div>
                        </v-card>
                        <div v-else
                            class="no-product-block"
                        >
                            <product-summary-component v-if="destinationProduct != null"
                                :display-product="destinationProduct"
                            ></product-summary-component>
                            <v-card v-else
                                light
                                outlined
                                class="no-product-block"
                            ></v-card>
                        </div>
                    </v-col>
                </v-row>
            </div>
        </v-card>
    </v-overlay>
</div>
</template>
<script>
import { mapState } from 'vuex'
import { ADD_NOTIFICATION } from "@/store/actions.type";

import ShopApiService from "@/common/api/shop.service";
import ProductApiService from "@/common/api/product.service";
import ProductSummaryComponent from "@/views/product/components/ProductSummaryComponent";
import _ from 'lodash';

import {
    SET_CURRENT_PARTNER_SHOP, SET_IS_LOADING_PRODUCTS_BY_SHOP, SET_SHOW_PRODUCT_MAPPER,
    SET_CURRENT_VIEW_SHOP_PRODUCT, 
} from "@/store/mutations.type";

export default {
    name: "ProductMapperBlock",
    data: function() {
        return {
            selectedProductId: null,
            destinationProduct: null,
            matchingProducts: [],
            search: null,
            searchingMatchingProducts: false,
            loadingDestinationProduct: false,
            isMappingProducts: false,
        };
    },
    props: [ 'partnerShopId' ],
    components: {
        ProductSummaryComponent,
    },
    created: function() {
        this.debouncedSearchProducts = _.debounce(this.searchProducts, 500);
    },
    computed: {
        ...mapState("shop", [ "currentShop" ]),
        ...mapState("product", [ "showProductMapper", "currentViewShopProduct", "currentPartnerShop" ]),
        showBlock() {
            return (
                this.showProductMapper && 
                this.currentViewShopProduct != null
            );
        },
        showLoadingData() {
            return (this.currentPartnerShop === null);
        },
    },
    watch: {
        search(val) {
            console.log(val);
            this.debouncedSearchProducts();
        },
        selectedProductId(newId, oldId) {
            if (newId != oldId && newId != null) {
                this.getDestinationProduct();
            }
        },
    },
    mounted() {
        this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, null);
        if (this.currentViewShopProduct != null && this.partnerShopId != null) {
            ShopApiService.getShop(this.partnerShopId)
                .then(({data}) => {
                    if (data.success) {
                        this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, data.store);
                    } 
                })
                .catch((error) => {
                    console.log(error);
                });
        }

    },
    methods: {
        closeDialog() {
            this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
            this.$store.commit(`product/${SET_SHOW_PRODUCT_MAPPER}`, false);
            this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
            this.$store.commit(`product/${SET_CURRENT_PARTNER_SHOP}`, null);
        },
        searchProducts() {
            if (this.search != null && this.search.length > 3) {
                this.searchingMatchingProducts = true;
                new Promise((resolve) => {
                    ProductApiService.searchProduct(this.currentShop.id, this.search)
                        .then(({data}) => {
                            if (data.success) {
                                this.matchingProducts = data.products;
                            }
                            this.searchingMatchingProducts = false;
                            resolve();
                        })
                        .catch((error) => {
                            console.log(error);
                            this.searchingMatchingProducts = false;
                        });
                });
            }
        },
        getDestinationProduct() {
            this.loadingDestinationProduct = true;
            new Promise((resolve) => {
                ProductApiService.loadProductOnShop(this.currentShop.id, this.selectedProductId)
                    .then(({ data }) => {
                        if (data.success) {
                            this.destinationProduct = data.product;
                        }
                        this.loadingDestinationProduct = false;
                        resolve();
                    })
                    .catch((error) => {
                        console.log(error);
                        this.loadingDestinationProduct = false;
                    });
            });
        },
        mapProducts() {
            this.isMappingProducts = true;
            new Promise((resolve) => {
                ProductApiService.mapProducts(this.partnerShopId, this.currentShop.id, this.currentViewShopProduct.ref_id, this.destinationProduct.ref_id)
                    .then(({ data }) => {
                        if (data.success) {
                            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                                notification: {
                                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                                    position: "top-left",
                                    type: "info",
                                    body: "Product ("+this.currentViewShopProduct.title+") successfully linked.",
                                    state: 0,
                                    length: 6000, // seconds
                                    data: null,
                                },
                            }, {root:true});
                        } else {
                            this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                                notification: {
                                    id: 'N' + (Math.floor(Math.random() * 100000000)),
                                    position: "top-right",
                                    type: "error",
                                    body: data.errors[0],
                                    state: 0,
                                    length: 6000, // seconds
                                    data: null,
                                },
                            }, {root:true});
                        }

                        this.isMappingProducts = false;
                        resolve();
                    })
                    .catch((error) => {
                        console.lgo(error);
                        this.isMappingProducts = false;
                    });
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.product-mapper-block {
    .content {
        border-radius: 0px;
        position: fixed;
        overflow-y: scroll;
        background-color: #fff;
        color: #333;
        height: 100%;
        top: 0;
        right: 0;
        min-width: 800px;
        max-width: 1000px;
        .loading-box {
            margin: auto;
        }
        .row {
            margin: 0px;
        }
        .establish-link-btn {
            padding: 10px 50px;
        }
        .products-holding-bar,
        .search-shop-bar {
            overflow: hidden;
            .left {
                padding-right: 40px;
            }
            .right {
                padding-left: 40px;
            }
            .shop-info-wrapper {
                .info-block {
                    padding: 0px;
                    overflow: hidden;
                    .to-label,
                    .from-label {
                        width: 70px;
                        text-align: center;
                        background-color: #CFD8DC;
                        line-height: 50px;
                    }
                    .to-label {
                        background-color: #FFF3E0;
                    }
                    .shop {
                        padding: 10px 20px 10px 30px;
                        box-sizing: border-box;
                        .v-image {
                            float: left;
                        }
                        .shop-name {
                            float: left;
                            padding-left: 15px;
                            line-height: 29px;
                        }
                    }
                }
            }
        }
        .products-holding-bar {
            .no-product-block {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>