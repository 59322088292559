<template>
<div class="product-summary-wrapper">
    <v-card
        light
        outlined
    >
        <v-card-title class="mb-4">
            <div class="product-desc pr-5">
                <h2 class="mb-3">{{ displayProduct.title }}</h2>
                <div>Product ID: {{ displayProduct.ref_id }}</div>
                <div>Vendor: {{ displayProduct.vendor }}</div>
                <div>Product Type: {{ displayProduct.type }}</div>
            </div>
            <v-img
                :src="displayProduct.image"
            ></v-img>
        </v-card-title>
        <div class="variants-list">
            <table class="variants-table" CELLSPACING="0">
                <thead>
                    <tr class="text-left">
                        <th>TITLE</th>
                        <th>SKU</th>
                        <th class="text-right">QUANTITY</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(variant, index) in displayProduct.variants" :key="index">
                        <td class="vtitle">{{ variant.title }}</td>
                        <td class="vsku">{{ variant.sku }}</td>
                        <td class="vqty text-right">{{ variant.inventory_quantity }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </v-card>
</div>
</template>
<script>
export default {
    name: "ProductSummaryComponent",
    props: ["displayProduct"],
}
</script>
<style lang="scss" scoped>
.product-summary-wrapper {
    .product-desc {
        width: 60%;
        float: left
    }
    .v-image {
        width: 40%;
    }
    .variants-list {
            padding: 10px 32px;
            margin-top: 15px;
            .variants-table {
                width: 100%;
                max-width: 600px;
                th {
                    border-bottom: 1px solid #333;
                }
                .vtitle {
                    height: 30px;
                }
            }
        }
}
</style>