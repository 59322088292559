<template>
<div class="product-detail-block" v-if="showBlock">
    <v-overlay>
        <v-card class="content">
            <v-toolbar 
                @click="closeDialog()"
                color="white"
                class="text--primary top-bar"
                flat
            >
                <v-icon
                    class="icon text--primary mr-5"
                >
                    keyboard_arrow_left
                </v-icon>
                Back to product list
            </v-toolbar>
            <div class="image-desc-block">
                <v-row>
                    <v-col cols="5">
                        <div v-for="(image, index) in currentViewShopProduct.images" :key="index"
                            class="images-holder float-left"
                        >
                            <v-img
                                v-if="typeof(image) != 'undefined'"
                                :src="image.src"
                                width="80"
                            ></v-img>
                        </div>
                    </v-col>
                    <v-col cols="7">
                        <h2 class="mb-3">{{ currentViewShopProduct.title }}</h2>
                        <div class="product-attrs mb-5">
                            <div>Product ID: {{ currentViewShopProduct.ref_id }}</div>
                            <div>Product Status: {{ currentViewShopProduct.status }}</div>
                            <div>Vendor: {{ currentViewShopProduct.vendor }}</div>
                        </div>
                        <div class="product-description">
                            <h4 class="mb-1">PRODUCT DESCRIPTION</h4>
                            <p v-html="currentViewShopProduct.description"></p>
                        </div>
                    </v-col>
                </v-row>
            </div>
            <div class="variants-list">
                <h2><strong>{{ currentViewShopProduct.variants.length }}</strong> variants in total</h2>
                <table class="variants-table" CELLSPACING="0">
                    <thead>
                        <tr class="text-left">
                            <th>TITLE</th>
                            <th>SKU</th>
                            <th>QUANTITY</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(variant, index) in currentViewShopProduct.variants" :key="index">
                            <td class="vtitle">{{ variant.title }}</td>
                            <td class="vsku">{{ variant.sku }}</td>
                            <td class="vqty">{{ variant.inventory_quantity }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </v-card>
    </v-overlay>
</div>
</template>
<script>
import { mapState } from "vuex";
import {
    SET_IS_LOADING_PRODUCTS_BY_SHOP, SET_SHOW_PRODUCT_DETAIL, SET_CURRENT_VIEW_SHOP_PRODUCT,
} from "@/store/mutations.type";

export default {
    name: "ProductDetailBlock",
    data: function() {
        return {
            isOverlayOn: false,
            images: [],
        };
    },
    props: [ 'partnerShopId' ],
    computed: {
        ...mapState("product", [ "showProductDetail", "currentViewShopProduct"]),
        showBlock() {
            return (this.showProductDetail && this.currentViewShopProduct != null);
        },
    },
    methods: {
        closeDialog(){
            this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
            this.$store.commit(`product/${SET_SHOW_PRODUCT_DETAIL}`, false);
            this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
        },
    }
}
</script>
<style lang="scss" scoped>
.product-detail-block {
    .content {
        border-radius: 0px;
        position: fixed;
        overflow-y: scroll;
        max-width: 800px;
            max-width: 1000px;
        background-color: #fff;
        color: #333;
        height: 100%;
        top: 0;
        right: 0;
        .top-bar {
            padding-left: 10px;
        }
        .row {
            margin: 0px;
        }
        .image-desc-block {
            padding-left: 3px;
            .images-holder {
                padding: 0px 10px 0px 30px;
            }
        }
        .variants-list {
            padding: 10px 32px;
            margin-top: 15px;
            h2 {
                margin-bottom: 15px;
            }
            .variants-table {
                width: 100%;
                min-width: 500px;
                max-width: 600px;
                th {
                    border-bottom: 1px solid #333;
                }
                .vtitle {
                    height: 30px;
                }
            }
        }
    }
}
</style>