<template>
  <v-container class="product-management-page">
    <div v-if="currentShop === null || isLoading || isLoadingConnectedShops">
        <data-preparation-overlay></data-preparation-overlay>
    </div>
    <v-row>
        <v-col cols="12">
            <h1>
                <v-icon
                    aria-hidden="false"
                    dark
                    left
                    large
                    color="orange darken-2"
                >
                sync
                </v-icon>
                Sync Products
            </h1>
        </v-col>
    </v-row>
    <v-row>
      <v-col cols="3">
        <h2>
          <v-icon
            block
            class="mr-3"
          >
            store
          </v-icon>
          <span>
            {{ partnerStoreLabel }}
          </span>
        </h2>
      </v-col>
      <v-col cols="6">
        <v-select
          v-model="selectedShopId"
          :items="connectedShops"
          item-value="id"
          item-text="store_domain"
          :label="'Select a '+partnerStoreLabel"
          outlined
          dense
          @change="loadProductsByShop()"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          v-model="selectedProductsList"
          :headers="tableHeaders"
          :items="currentShopProducts"
          :loading="(isLoading || currentShop === null)"
          show-select
        >
          <template v-slot:top>
            <table-control-panel></table-control-panel>
            <div class="selection-actions">
              <span v-if="selectedProductsList.length > 0 && !selectAllProducts"
                class="float-left"
              >
                {{ selectedProductsList.length }} products selected
              </span>
              <span v-else-if="selectedProductsList.length >= 10 && selectAllProducts"
                class="float-left"
              >
                Selected all products
              </span>
              <span class="select-all-checkbox-block" v-if="selectedProductsList.length >= 10">
                <v-checkbox v-model="selectAllProducts"
                  class="float-left ma-0 all-product-checkbox"
                ></v-checkbox>
                <span class="float-left">Select all products</span>
              </span>

              <span class="ml-5" v-if="selectedProductsList.length > 1">
                <v-btn
                  outlined
                  dense
                  class="mr-1"
                  @click="bulkSyncProducts()"
                >
                  Bulk Sync
                </v-btn>
                <v-btn
                  outlined
                  dense
                  @click="showBulkUnsyncOptionDialog = true"
                >
                  Bulk Unsync
                </v-btn>
              </span>
            </div>
          </template>


          <template v-slot:item.title="{ item }">
            <v-card-text
              class="product-title-block"
            >
              <v-img
                width="50"
                :src="item.default_image_url"
                class="float-left mr-3"
              >
              </v-img>
              <h3
                class="float-left mr-5"
              >{{ item.title }}</h3>
              <div
                class="float-left view-detail-btn"
                @click="openProductDecDialog(item)"
              >
                View Detail
                <v-icon>
                  keyboard_arrow_right
                </v-icon>
              </div>
            </v-card-text>
          </template>
          <template v-slot:item.total_quantity="{ item }">
            <v-card-text v-if="item.total_quantity > 0">
              {{ item.total_quantity }}
            </v-card-text>
            <v-card-text v-else>0</v-card-text>
          </template>
          <template v-slot:item.status="{ item }">
            <v-card-text class="pl-0">
                <v-chip
                  v-if="getProductStatus(item) != null"
                  :class="{ 'red lighten-4 error--text': (getProductStatus(item) === 'error'), 'yellow': (getProductStatus(item) === 'pending')}"
                >
                  <v-icon v-if="getProductStatus(item) === 'pending'"
                    class="status-circle orange--text rotate" 
                  >sync</v-icon>
                  <v-icon v-else
                    class="status-circle"
                  >stop_circle</v-icon>
                  {{ getProductStatus(item) }}
                </v-chip>
                <v-chip
                  v-else-if="item.mapper_id !=  null"
                  color="green lighten-5 success-status-chip"
                >
                  <v-icon
                    class="status-circle"
                  >stop_circle</v-icon>
                  Synced
                </v-chip>
                <v-chip
                  v-else
                >
                  <v-icon
                    class="status-circle"
                  >stop_circle</v-icon>
                  Not Synced
                </v-chip>
            </v-card-text>
          </template>
          <template v-slot:item.actions="{ item }">
              <div>
                  <div v-if="item.mapper_id != null">
                    <v-btn
                      outlined
                      small
                      class="action-btn"
                      @click="openUnsyncProductDialog(item)"
                    >Unlink product</v-btn>
                  </div>
                  <div v-else>
                    <v-btn
                      outlined
                      small
                      class="mr-1 action-btn"
                      @click="openProductMapperDialog(item)"
                    >Link to Existing</v-btn>
                    <v-btn
                      outlined
                      small
                      class="action-btn"
                      @click="addImportProductAction(item)"
                    >Import</v-btn>
                  </div>
              </div>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay v-if="isUnsyncOptionsDialogOpened">
      <v-card
        light
        class="unsync-options-dialog"
      >
        <v-toolbar dark>
          <v-card-title color="dark">
            <v-icon
                color="red"
                class="mr-5"
            >
                error_outline
            </v-icon>
            <div class="title-text">
                <div>Unsync Options</div>
                <small>To unsync a product please choose one of the following options.</small>
            </div>
            <v-icon
                    class="dc-close"
                    @click="isUnsyncOptionsDialogOpened = false"
            >
                close
            </v-icon>
          </v-card-title>
        </v-toolbar>
        <v-card-text
              class="overflow-hidden"
          >
              <h3>Unsync & Keep Product</h3>
              <p>
                  Select this option if you would like to unsync the product without deleting the product on your store.<br>
                  NOTE: If you plan to resync this product, you need to map it. Syncing again will create a duplicate product! 
              </p>
              <v-btn
                  color="primary"
                  class="float-right"
                  @click="addUnsyncProductAction(targetProduct, true)"
              >
                  Unsync & Keep
              </v-btn>
          </v-card-text>
          <div class="divline"></div>
          <v-card-text
              class="overflow-hidden"
          >
              <h3>Unsync & Delete Product</h3>
              <p>Select this option if you would like to unsync the product and delete the product from your store.</p>
              <v-btn
                  color="red"
                  class="float-right"
                  @click="addUnsyncProductAction(targetProduct, false)"
              >
                  Unsync & DELETE
              </v-btn>
          </v-card-text>
      </v-card>
    </v-overlay>

    <!-- Bulk Unsync Options -->
    <v-overlay v-if="showBulkUnsyncOptionDialog">
      <v-card
        light
        class="unsync-options-dialog"
      >
        <v-toolbar dark>
          <v-card-title color="dark">
            <v-icon
                color="red"
                class="mr-5"
            >
                error_outline
            </v-icon>
            <div class="title-text">
                <div>BULK Unsync Options</div>
                <small>To unsync a product please choose one of the following options.</small>
            </div>
            <v-icon
                    class="dc-close"
                    @click="showBulkUnsyncOptionDialog = false"
            >
                close
            </v-icon>
          </v-card-title>
        </v-toolbar>
        <v-card-text
              class="overflow-hidden"
          >
              <h3>Unsync & Keep Product</h3>
              <p>
                  Select this option if you would like to unsync the product without deleting the product on your store.<br>
                  NOTE: If you plan to resync this product, you need to map it. Syncing again will create a duplicate product! 
              </p>
              <v-btn
                  color="primary"
                  class="float-right"
                  @click="bulkUnsyncProducts(true)"
              >
                  Unsync & Keep
              </v-btn>
          </v-card-text>
          <div class="divline"></div>
          <v-card-text
              class="overflow-hidden"
          >
              <h3>Unsync & Delete Product</h3>
              <p>Select this option if you would like to unsync the product and delete the product from your store.</p>
              <v-btn
                  color="red"
                  class="float-right"
                  @click="bulkUnsyncProducts(false)"
              >
                  Unsync & DELETE
              </v-btn>
          </v-card-text>
      </v-card>
    </v-overlay>

    <product-detail-block v-if="currentViewShopProduct != null"
      :partner-shop-id="this.selectedShopId"
    ></product-detail-block>
    <product-mapper-block v-if="currentViewShopProduct != null"
      :partner-shop-id="this.selectedShopId"
    ></product-mapper-block>

  </v-container>
</template>

<script>
import { mapState } from "vuex";
import { 
  SET_SELECTED_PRODUCT_SHOP_ID, SET_CURRENT_SHOP_PRODUCTS, SET_CURRENT_VIEW_SHOP_PRODUCT,
  SET_SHOW_PRODUCT_DETAIL, SET_SHOW_PRODUCT_MAPPER, SET_IS_LOADING_PRODUCTS_BY_SHOP
 } from "@/store/mutations.type";

 import { ADD_ACTION, ADD_NOTIFICATION, LOAD_CONNECTED_SHOPS, ADD_PRODUCT_ACTION_STATUS, LOAD_PRODUCTS_BY_SHOP_ID } from "@/store/actions.type";

import TableControlPanel from "../components/TableControlPanel";
import ProductDetailBlock from "../components/ProductDetailBlock";
import ProductMapperBlock from "../components/ProductMapperBlock";
import ProductApiService from "@/common/api/product.service";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";

export default {
  name: "ProductManagementPage",
  data: function() {
    return {
        tableHeaders: [
          { text: "Product", value: "title", sortable: true },
          { text: "Inventory", value: "total_quantity", sortable:true },
          { text: "Status", value: "status", sortable: true },
          { text: "Actions", value: "actions", sortable: false },
        ],
        selectedShopId: null,
        searchStr: null,
        targetProduct: null,
        isUnsyncOptionsDialogOpened: false,
        selectedProductsList: [],
        selectAllProducts: false,
        showBulkUnsyncOptionDialog: false,
    };
  },
  props: [ "productPageEntryShopId" ],
  components: {
    TableControlPanel,
    ProductDetailBlock,
    DataPreparationOverlay,
    ProductMapperBlock,
  },
  computed: {
        ...mapState("shop", ["connectedShops", "currentShop", "isLoadingConnectedShops"]),
        ...mapState("product", ["showProductMapper", "isLoadingProductsByShop", "currentShopProducts", "currentViewShopProduct", "productActionStatusMapper"]),
        partnerStoreLabel() {
            if (this.currentShop != null && this.currentShop.type != "source") {
                return "Source store";
            } else {
                return "Destination store";
            }
        },
        isLoading() {
          return this.isLoadingProductsByShop || this.isLoadingConnectedShops;
        },
        selectedShop () {
          let selectedShop = this.connectedShops.find((shop) => {
            return shop.id === this.selectedShopId;
          });
          return selectedShop;
        }
    },
    created: function() {
      this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);
      if (this.productPageEntryShopId != null) {
        this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, { searchStr: null });
        // We need this in case the page on specific shop's products are requested from another page
        this.selectedShopId = this.productPageEntryShopId;
        this.loadProductsByShop();
      } else if (this.currentShop === null) {
        this.$store.dispatch(`shop/init`);
        this.$store.dispatch(`shop/${LOAD_CONNECTED_SHOPS}`, { searchStr: null });
      }
    },
    methods: {
      loadProductsByShop() {
        this.$store.commit(`product/${SET_SELECTED_PRODUCT_SHOP_ID}`, this.selectedShopId);
        this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);
        let sourceStoreId = this.currentShop.id;
        let destinationStoreId = this.selectedShopId;

        if (this.currentShop.type != 'source') {
          sourceStoreId = this.selectedShopId;
          destinationStoreId = this.currentShop.id
        }

        this.$store.dispatch(`product/${LOAD_PRODUCTS_BY_SHOP_ID}`, {
          sourceId: sourceStoreId, 
          destinationId: destinationStoreId,
          searchStr: this.searchStr,
        });
      },
      openProductDecDialog(product) {
        // DO it here because some of the mandatory params is in this component
        this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, true);
        this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
        return new Promise((resolve) => {
          ProductApiService.loadProductOnShop(product.store_id, product.external_product_id)
            .then(({ data }) => {
              if (data.success) {
                this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, data.product);
                this.$store.commit(`product/${SET_SHOW_PRODUCT_DETAIL}`, true);
              }
              this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
              resolve();
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
            });
        });
      },
      openProductMapperDialog(product) {
        this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, true);
        this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, null);
        return new Promise((resolve) => {
          ProductApiService.loadProductOnShop(product.store_id, product.external_product_id)
            .then(({ data }) => {
              if (data.success) {
                this.$store.commit(`product/${SET_CURRENT_VIEW_SHOP_PRODUCT}`, data.product);
                this.$store.commit(`product/${SET_SHOW_PRODUCT_MAPPER}`, true);
              }
              resolve();
              this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
            })
            .catch((error) => {
              console.log(error);
              this.$store.commit(`product/${SET_IS_LOADING_PRODUCTS_BY_SHOP}`, false);
            });
        });
      },
      openUnsyncProductDialog (product) {
        this.targetProduct = product;
        this.isUnsyncOptionsDialogOpened = true;
      },
      addImportProductAction(productObj) {
        if (typeof(this.selectedShop) === 'undefined') {
          return;
        }
        this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
          connectionId: this.selectedShop.connection_id,
          productId: productObj.id,
          statusValue: 'pending',
          message: 'product pending for import',
        });
        //  yes we found it
        let connectionId = this.selectedShop.connection_id;
        this.$store.dispatch(`processQueue/${ADD_ACTION}`, {
          actionType: 'product_import',
          param: {
            connection_id: connectionId,
            product: productObj,
            productStatusKey: this.selectedShop.connection_id + ':' + productObj.id,
          }
        });
      },
      addUnsyncProductAction(productObj, keepProduct) {
        if (typeof(this.selectedShop) === 'undefined') {
          return;
        }
        this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
          connectionId: this.selectedShop.connection_id,
          productId: productObj.id,
          statusValue: 'pending',
          message: 'product pending for unsync',
        });
        this.$store.dispatch(`processQueue/${ADD_ACTION}`, {
          actionType: 'product_unsync',
          param: {
            keepProduct: keepProduct,
            product: productObj,
            productStatusKey: this.selectedShop.connection_id + ':' + productObj.id,
          }
        });
        this.targetProduct = null;
        this.isUnsyncOptionsDialogOpened = false;
      },
      bulkSyncProducts() {
        if (this.selectAllProducts) {
          return new Promise((resolve) => {
            ProductApiService.syncAllProducts(this.selectedShop.connection_id)
              .then(({ data }) => {
                if (data.success) {
                  this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                      notification: {
                          id: 'N' + (Math.floor(Math.random() * 100000000)),
                          position: "top-left",
                          type: "info",
                          body: "All products sync task has been queued. Please come back and check it later.",
                          state: 0,
                          length: 6000, // seconds
                          data: null,
                      },
                  }, {root:true});
                }
                resolve();
              })
              .catch((error) => {
                console.log(error);
              });
          });
        } else {
          this.selectedProductsList.forEach((product) => {
            if (product.mapper_id === null) {
              this.addImportProductAction(product);
            }
          });
        }
      },
      bulkUnsyncProducts(shouldKeepProduct) {
        if (this.selectAllProducts) {
          return new Promise((resolve) => {
            ProductApiService.unsyncAllProducts(this.selectedShop.connection_id, shouldKeepProduct)
              .then(({ data }) => {
                if (data.success) {
                  this.$store.dispatch(`notification/${ADD_NOTIFICATION}`, {
                      notification: {
                          id: 'N' + (Math.floor(Math.random() * 100000000)),
                          position: "top-left",
                          type: "info",
                          body: "All products unsync task has been queued. Please come back and check it later.",
                          state: 0,
                          length: 6000, // seconds
                          data: null,
                      },
                  }, {root:true});
                }
                this.showBulkUnsyncOptionDialog = false;
                resolve();
              })
              .catch((error) => {
                console.log(error);
                this.showBulkUnsyncOptionDialog = false;
              });
          });
        } else {
          this.selectedProductsList.forEach((product) => {
            if (product.mapper_id != null) {
              this.$store.dispatch(`product/${ADD_PRODUCT_ACTION_STATUS}`, {
                connectionId: this.selectedShop.connection_id,
                productId: product.id,
                statusValue: 'pending',
                message: 'product pending for unsync',
              });
              this.addUnsyncProductAction(product, shouldKeepProduct);
            }
          });
        }
      },
      getProductStatus(product) {
        let status = null;
        if (this.selectedShop != null) {
          let identifier = this.selectedShop.connection_id + ':' +product.id;
          this.productActionStatusMapper.forEach((item) => {
            if (item.key ===  identifier) {
              status = item.status;
            }
          });
        }
        return status;
      }
    }
}
</script>

<style lang="scss">
.product-management-page {
  .product-title-block {
    overflow: hidden;
    > h3,
    > div {
      line-height: 50px;
    }
    .view-detail-btn {
      color: #777;
    }
  }
  .success-status-chip {
    color: #43A047;
  }
  .status-circle {
    font-size: 12px;
    margin-right: 10px;
  }
  .v-input {
    .v-input__slot {
      background-color: #fff;
    }
  }
  .action-btn {
    border-radius: 5px;
  }
  .unsync-options-dialog {
    .title-text {
        line-height: 18px;
    }
    .divline {
        border-bottom: 1px solid #ccc;
        margin: auto 15px;
    }
    .dc-close {
        position: absolute;
        right: 15px;
    }
  }
  .selection-actions {
    overflow: hidden;
    font-weight: 700;
    padding: 0px 20px;
    > span {
      line-height: 35px;
      margin-right: 10px;
    }
    .select-all-checkbox-block {
      overflow: hidden;
      .all-product-checkbox {
        padding-top: 4px;
      }
    }
  }
}

.rotate {
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-359deg);
  }
}
</style>