<template>
    <div class="table-control-panel p-3">
        <div class="controls">
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="searchStr"
                        label="Search products"
                        prepend-inner-icon="search"
                        outlined
                        dense
                        @change="searchProducts()"
                    ></v-text-field>
                </v-col>
                <v-col cols="3">
                    <v-select
                        v-model="statusFilter"
                        :items="availableStatus"
                        label="Sync status"
                        outlined
                        dense
                        @change="searchProducts()"
                    ></v-select>
                </v-col>
                <v-col cols="3">
                    <v-btn
                        outlined
                        dense
                        class="mr-1"
                        @click="showMoreFilters = !showMoreFilters"
                    >More Filers</v-btn>
                    <v-card v-if="showMoreFilters"
                        class="filter-options"
                    >
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>INVENTORY</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" 
                                            v-model="filterStockOnly"
                                            @change="searchProducts()"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Exculde no stock items</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>

                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>PUBLISH STATUS</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" 
                                            v-model="filterIncludeActive"
                                            @change="searchProducts()"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Active</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" 
                                            v-model="filterIncludeDraft"
                                            @change="searchProducts()"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Draft</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card>
                    <v-btn
                        outlined
                        dense
                        @click="showSortByOptions = !showSortByOptions;"
                    >
                        <span class="mr-3">Sort</span>
                        <v-icon>sort</v-icon>
                    </v-btn>
                    <v-card v-if="showSortByOptions"
                        class="sortby-options"
                    >
                        <v-list>
                            <v-list-item>
                                <v-list-item-content>
                                    <v-list-item-title>PUBLISH DATE</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                            <v-list-item>
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" 
                                            v-model="oldToNew"
                                            @change="searchProducts()"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Oldest to newest</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                            <v-list-item>
                                <template v-slot:default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active" 
                                            v-model="newToOld"
                                            @change="searchProducts()"
                                        ></v-checkbox>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>Newest to oldest</v-list-item-title>
                                    </v-list-item-content>
                                </template>
                            </v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-if="hasMultiSelections">
                <v-col cols="12">
                    <span class="float-left" v-if="selectAllProducts">all products</span>
                    <span class="float-left" v-else>{{ selectedCount }} products selected</span>
                    <span class="float-left select-all-product-option" @click="selectAllProductsOption = !selectAllProductsOptions">Select All Product</span>
                    <v-btn class="float-left">Bulk Sync</v-btn>
                    <v-btn class="float-left">Bulk Unsync</v-btn>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import { mapState } from 'vuex';
import { SET_CURRENT_SHOP_PRODUCTS } from "@/store/mutations.type";
import { LOAD_PRODUCTS_BY_SHOP_ID } from "@/store/actions.type";

export default {
    name: "TableControlPanel",
    data: function() {
        return {
            searchStr: null,
            availableStatus: [ "all", "synced", 'not synced', 'error'],
            statusFilter: null,
            selectAllProductsOption: false,
            showMoreFilters: false,
            showSortByOptions: false,
            filterStockOnly: false,
            filterIncludeActive: false,
            filterIncludeDraft: false,
            oldToNew: false,
            newToOld: false,
        };
    },
    computed: {
        ...mapState("shop", ["currentShop"]),
        ...mapState("product", ["selectProductShopId", "currentShopProducts"]),
        selectedCount() {
            let selectCount = 0;
            this.currentShopProducts.forEach((product) => {
                if (product.isSelected) {
                    selectCount ++;
                }
            });
            return selectCount;
        },
        hasMultiSelections() {
            return this.selectedCount > 1;
        },
    },
    created: function() {
      //TODO use change the code below for each str 
      // this.debouncedGetAnswer = _.debounce(this.getAnswer, 500)
    },
    methods: {
        searchProducts() {
            this.$store.commit(`product/${SET_CURRENT_SHOP_PRODUCTS}`, []);
            let sourceStoreId = this.currentShop.id;
            let destinationStoreId = this.selectProductShopId;
            let productFilters = [];
            // let sortByParam = null;

            if (this.currentShop.type != 'source') {
                sourceStoreId = this.selectProductShopId;
                destinationStoreId = this.currentShop.id
            }
            if (this.statusFilter != null && this.statusFilter != 'all') {
                if (this.statusFilter === 'not synced') {
                    productFilters.push('unsynced');
                } else {
                    productFilters.push(this.statusFilter);
                }
            }
            if (this.filterStockOnly) {
                productFilters.push('has_stock');
            }
            if (this.filterIncludeActive && !this.filterIncludeDraft){
                productFilters.push('published');
            }
            if (this.filterIncludeDraft && !this.filterIncludeActive) {
                productFilters.push('unpublished')
            }

            // this sort by should be a single option not a checkbox in the design....
            // if (this.newToOld) {
            //     sortByParam = {
            //         sort_by: 'published_at',
            //         sort_by_desc: true,
            //     };
            // }
            // if (this.oldToNew && this.) {
            //     sortByParam = {
            //         sort_by: 'published_at',
            //         sort_by_desc: true,
            //     };
            // }
            this.$store.dispatch(`product/${LOAD_PRODUCTS_BY_SHOP_ID}`, {
                sourceId: sourceStoreId, 
                destinationId: destinationStoreId,
                searchStr: this.searchStr,
                filters: productFilters,
                // sortBy: sortByParam,
            });
        }
    }
}
</script>
<style lang="scss" scoped>
.table-control-panel {
    .controls {
        overflow: hidden;
        padding: 20px 25px;
        .sortby-options,
        .filter-options {
            position: absolute;
            z-index: 2;
            border-radius: 3px;
        }
    }
}
</style>